/* @import url('https://fonts.googleapis.com/css2?family=Caladea:wght@400;700&family=Fraunces:opsz,wght@9..144,400;9..144,600&family=Gantari:wght@400;600&family=Sorts+Mill+Goudy&display=swap'); */
@import url("https://p.typekit.net/p.css?s=1&k=yxv3xss&ht=tk&f=5165&a=29213780&app=typekit&e=css");

/* @font-face {
  font-family:"ff-folk";
  src:url("https://use.typekit.net/af/e0aab4/000000000000000077359a06/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e0aab4/000000000000000077359a06/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e0aab4/000000000000000077359a06/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  } */

@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@400;600;700;800&display=swap');

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

body {
  height: auto
}
  